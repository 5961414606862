<template>
  <section class="page">
    <div class="page-title-block">
      <div
        @click="$router.push('/staff')"
        class="d-flex align-center description"
      >
        <v-icon class="mr-3 pointer">mdi-arrow-left</v-icon>
        <h3>Ставки специалистов</h3>
      </div>

      <v-btn
        class="btn-edit radius-root"
        width="210"
        height="52"
        :disabled="!valid"
        @click="saveRates()"
      >
        Сохранить
      </v-btn>

    </div>
    <div
      v-if="loading"
      class="d-flex justify-center mt-15"
    >
      <Loader />
    </div>

    <div v-else>
      <v-form
        ref="form"
        v-model="valid"
        class="mt-9"
      >
        <div class="rates-list">
          <div
            class="rates-list__item mb-5"
            v-for="(el, idx) in positions"
            :key="idx"
          >
            <v-text-field
              v-model="el.codename"
              label="Должность"
              class="position rounded-lg"
              required
              :rules="nameRules"
              outlined
              rounded
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="el.payment_per_hour"
              label="Ставка"
              class="rounded-lg"
              required
              :rules="nameRules"
              hide-details
              outlined
              rounded
            ></v-text-field>

            <v-btn
              icon
              class="ml-4"
              @click="deleteRate(idx)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </v-form>

      <v-btn
        outlined
        class="btn-add radius-root mt-3"
        height="52"
        @click="addRate()"
      >+ Добавить специалиста</v-btn>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/UI/Loader.vue';

export default {
  name: 'SpecialistRates',

  data() {
    return {
      valid: true,
      loading: true,
    };
  },

  computed: {
    ...mapState('staff', ['positions']),
  },

  methods: {
    ...mapActions('staff', ['getPositions', 'addPositions']),

    addRate() {
      this.positions.push({ codename: '', payment_per_hour: '' });
    },

    deleteRate(index) {
      this.positions.splice(index, 1);
    },

    async saveRates() {
      const items = this.positions.map((el) => ({
        ...el,
        payment_per_hour: Number(el.payment_per_hour),
      }));

      await this.addPositions(items);
    },
  },

  async mounted() {
    await this.getPositions();
    this.loading = false;
  },

  components: { Loader },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";
.page-title-block {
  .description {
    cursor: pointer;
  }
}
.theme--light.v-input--is-disabled input {
  color: $base-text;
}
.rates-list {
  display: flex;
  flex-wrap: wrap;
}

.rates-list__item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.position {
  margin-right: 15px;
}
</style>
